<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/material-list"
      api-download-url="/material-list-export"
      download-file-name="npl.xlsx"
      @showUpdateImage="showUpdateImage"
      @showEMarketSQ="showEMarketSQ"
      @showDialogReservedQuantity="showDialogReservedQuantity"
      @showDialogCreateReserved="showDialogCreateReserved"
    ></custom-table>
  </div>
</template>

<script>
import {
  IMAGE_OPTIONS,
  GOODS_STATUS_OPTIONS,
  CUSTOM_VIEW_MATERIAL_WAREHOUSE_MATERIAL,
} from "@/libs/const";
/* import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http"; */

export default {
  name: "Index",
  components: {},
  data: () => ({
    customView: CUSTOM_VIEW_MATERIAL_WAREHOUSE_MATERIAL,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
    statusOptions: [...GOODS_STATUS_OPTIONS],
    dialogEMarketSQ: false,
    dialogUpdateImage: false,
    dialogReservedQuantity: false,
    dialogCreateReserved: false,
    updatingGoods: {},
  }),
  computed: {},
  created() {
    const columns = [
      {
        type: "select-material-warehouse",
        label: this.$t("labels.warehouse_1"),
        placeholder: this.$t("labels.warehouse_1"),
        name: "id_material_warehouse",
        hasSort: false,
        sortName: "material_warehouse_code",
        key: "material_warehouse_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "code",
        hasSort: false,
        sortName: "code",
        key: "code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: false,
        sortName: "sku",
        key: "sku",
        required: true,
      },
      {
        type: "select-category",
        label: this.$t("labels.category"),
        placeholder: this.$t("labels.category"),
        name: "id_category",
        hasSort: false,
        sortName: "category_name",
        key: "category_name",
      },
      {
        type: "select-filter",
        label: this.$t("labels.image"),
        placeholder: this.$t("labels.image"),
        name: "image",
        hasSort: false,
        sortName: "image",
        key: "image",
        options: this.imageOptions,
        required: true,
        component: "Image",
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "description",
        hasSort: false,
        sortName: "description",
        key: "description",
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: false,
        sortName: "size",
        key: "size",
      },
      {
        type: "input-filter",
        label: this.$t("labels.dxrxc"),
        placeholder: this.$t("labels.dxrxc"),
        name: "dxrxc",
        hasSort: false,
        sortName: "dxrxc",
        key: "dxrxc",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.weight"),
        placeholder: this.$t("labels.weight"),
        name: "weight",
        hasSort: false,
        sortName: "weight",
        key: "weight",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receiving_quantity"),
        placeholder: this.$t("labels.receiving_quantity"),
        name: "receiving",
        hasSort: false,
        sortName: "receiving",
        key: "receiving",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.export_quantity"),
        placeholder: this.$t("labels.export_quantity"),
        name: "total_picked",
        hasSort: false,
        sortName: "total_picked",
        key: "total_picked",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.available_quantity_1"),
        placeholder: this.$t("labels.available_quantity_1"),
        name: "available",
        hasSort: false,
        sortName: "available",
        key: "available",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.return_quantity"),
        placeholder: this.$t("labels.return_quantity"),
        name: "returning",
        hasSort: false,
        sortName: "returning",
        key: "returning",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [
      {
        text: this.$t("labels.sku"),
        key: "sumSku",
      },
      {
        text: this.$t("labels.avai_sku"),
        key: "sumAvaiSku",
      },
      {
        text: this.$t("labels.available_quantity_1"),
        key: "sumAvailable",
      },
      {
        text: this.$t("labels.receiving_quantity"),
        key: "sumReceiving",
      },
      {
        text: this.$t("labels.export_quantity"),
        key: "sumTotalPick",
      },
      {
        text: this.$t("labels.return_quantity"),
        key: "sumReturning",
      },
    ];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
  },
};
</script>

<style scoped></style>
